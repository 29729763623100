@charset "utf-8";

body.subscribed.receipt-detail-page, body.subscribed.diet-detail-page{
	.logo{
		padding-left: 10px;
	}
	.logo img{
		width: 120px;
	}
	.cookies, .registered-top-info, .top-actions, .user-menu, .footer {
		display: none;
	}
	.generic-content .flex-container{
		width: 100%;
		padding: 0;
	}
	.main-content{
		order: 1;
		-webkit-box-flex: 0 0 100%; /*iOS6, Safari 3.1-6*/
		-ms-flex: 0 0 100%; /*IE10*/
		-webkit-flex: 0 0 100%;/*android 4.3, IE mobile, Safari*/
		flex: 0 0 100%;
		min-width: 0;
		font-size: 12pt;
	}
	.aside-content{
		-webkit-box-flex: 0 0 100%; /*iOS6, Safari 3.1-6*/
		-ms-flex: 0 0 100%; /*IE10*/
		-webkit-flex: 0 0 100%;/*android 4.3, IE mobile, Safari*/
		flex: 0 0 100%;
		min-width: 0;
		order: 1;
		margin: 0;
	}
	.generic-content h3, .generic-content h1, .generic-content h2{
		margin: 0 0 5px 0;
		font-size: 18px!important;
	}
	.nutrition-info h3{
		text-align: left;
		padding: 10px 0;
		margin: 0;
	}
	.nutrition-info li{
		text-align: left;
		padding: 5px;
		margin: 0;
	}
	.SG-nutrition-value{
		text-align: left;
		float: none;
	}
	li, p{
		font-size: 12px!important;
		margin: 0 0 5px 0;
	}
	li{
		list-style-type: disc;
		list-style-position: inside;
		padding: 0;
	}
	.main-content{
		padding: 0 0 10px 0;
	}
}
body.subscribed.receipt-detail-page{
	.eating-options{
		ul{
			-webkit-columns: 2;
			   -moz-columns: 2;
			        columns: 2;
		}
	}
	.nutrition-info ul{
		-webkit-columns: 2;
		   -moz-columns: 2;
		        columns: 2;
	}
}
.print-button{
	display: none;
}